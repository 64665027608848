import React from 'react';
import Loader from 'react-loader-spinner';
import VideoModal from './VideoModal';
import { useReps } from '../api/useReps';
import useRepVideos from '../api/useRepVideos';
import useUpdateSelectedVideo from '../store/actions/useUpdateSelectedVideo';
import useUpdateScriptOptions from '../store/actions/useUpdateScriptOptions'
import useUpdateSelectedPrograms from '../store/actions/useUpdateSelectedPrograms';
import useUpdateSelectedDocuments from '../store/actions/useUpdateSelectedDocuments';
import useUpdateSelectedImages from '../store/actions/useUpdateSelectedImages';
import { defaultScriptDetails } from '../store/reducers/layout';

const textOver = {
  position: 'relative',
  margin: 0
};
const figCaption = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  boxSizing: 'border-box',
  minWidth: 60,
  height: 30,
  margin: 19,
  marginLeft: 8,
  marginBottom: 8,
  fontSize: 14,
  fontWeight: 700,
  paddingTop: 4,
  color: 'white',
  textAlign: 'center',
  background: '#343434'
};

const formatSeconds = seconds => {
  const mins = String(Math.floor(seconds / 60)).padStart(2, '0');
  const secs = String(seconds - mins * 60).padStart(2, '0');
  return `${mins}:${secs}`;
};

export default function ActivityTable() {
  const [{ repId, showVideoModal, activeVideo }, setState] = React.useState({ repId: 1, showVideoModal: false, activeVideo: null });
  const { users, loadingUsers } = useReps();
  const { videos, loading, handleRefresh } = useRepVideos();

  const updateSelectedVideo = useUpdateSelectedVideo();
  const updateScriptOptions = useUpdateScriptOptions();
  const updateSelectedDocuments = useUpdateSelectedDocuments();
  const updateSelectedPrograms = useUpdateSelectedPrograms();
  const updateSelectedImages = useUpdateSelectedImages();

  React.useEffect(() => {
    updateSelectedVideo(null);
    updateSelectedDocuments([]);
    updateSelectedPrograms([]);
    updateSelectedImages([]);
    updateScriptOptions(defaultScriptDetails);
  }, []);

  const showVideo = React.useCallback(id => () => setState(prev => ({ ...prev, showVideoModal: true, activeVideo: id })), [setState]);

  const handleCloseVideoModal = React.useCallback(() => setState(prev => ({ ...prev, showVideoModal: false, activeVideo: null })), [setState]);
  
  return (
    <div>
      {activeVideo !== null && (
        <div className={`${!showVideoModal ? 'hidden' : ''}`}>
          <VideoModal handleClose={handleCloseVideoModal} path={videos[activeVideo].path} title={videos[activeVideo].title} />
        </div>
      )}

      {loading && (
        <div className='activity-loader'>
          <Loader type='ThreeDots' color='#199dde' height={100} width={100} timeout={300000} />
        </div>
      )}

      {!loading && !loadingUsers && (
        <div className='app__activity-header'>
          <div className='app__activity-actions'>
            <h4>REP VIDEOS</h4>
            <div className='app__activity-actions__row'>

              <select className="app__attachments__brand" value={repId} id="all_rep_emails" onChange={e => {
                setState(prev => ({ ...prev, repId: e.target.value }));
              }}>
                {users.map((user, index) => (
                  <option className='unselectable' value={user.id} key={index}>
                    {user.email}
                  </option>
                ))}
              </select>

              <button className='app__button app__button--flat activity-action-submit' onClick={handleRefresh}>
                go
              </button>
            </div>
          </div>

          <section className='app__attachments__videos clearfix'>
            {videos.map((row, key) => (
              <div className='app__attachments__video-container' key={'video-' + key}>
                <figure style={textOver}>
                  <div
                    className='app__attachments__video'
                    style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.gif})` }}
                    onClick={showVideo(key)}
                  ></div>
                  {row.runtime && (
                    <figcaption style={figCaption}>
                      <span>{formatSeconds(row.runtime)}</span>
                    </figcaption>
                  )}
                </figure>

                <div className='app__attachments__header'>
                  <i className='fa fa-play' onClick={showVideo(key)}></i>
                </div>
                <p className='app__attachments__video-title'>{row.title}</p>
                <p className='app__attachments__video-dateadded'>{row.dateadded}</p>
              </div>
            ))}
          </section>

        </div>
      )}
    </div>
  );
}
