import React from 'react';
import { useAttachments, useTemplateAttachments } from '../api/useAttachments';
import useSelectedPrograms from '../store/selectors/useSelectedPrograms';
import useSelectedDocuments from '../store/selectors/useSelectedDocuments';
import useSelectedImages from '../store/selectors/useSelectedImages';
import useActiveBrand from '../store/selectors/useActiveBrand';
import VideoModal from './VideoModal';
import DocumentModal from './DocumentModal';
import ImageModal from './ImageModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import EditAttachmentModal from './EditAttachmentModal';
import { isActiveFeature } from '../utils/helpers.js';

const textOver = {
  position: 'relative',
  margin: 0
};
const figCaption = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  boxSizing: 'border-box',
  minWidth: 60,
  height: 30,
  margin: 19,
  marginLeft: 8,
  marginBottom: 8,
  fontSize: 14,
  fontWeight: 700,
  paddingTop: 4,
  color: 'white',
  textAlign: 'center',
  background: '#343434'
};

const formatSeconds = seconds => {
  const mins = String(Math.floor(seconds / 60)).padStart(2, '0');
  const secs = String(seconds - mins * 60).padStart(2, '0');
  return `${mins}:${secs}`;
};

export default function AttachmentGallery({ canSelect = false, canDelete = false }) {
  const [{ activeVideo, showVideoModal, activeDocument, activeImage, showDocumentModal, showImageModal, confirmDelete, attachmentToDelete, attachmentToEdit, showEditModal }, setState] = React.useState({
    activeVideo: null,
    activeDocument: null,
    activeImage: null,
    showVideoModal: false,
    showDocumentModal: false,
    showImageModal: false,
    confirmDelete: false,
    attachmentToDelete: null,
    attachmentToEdit: null,
    showEditModal: false
  });

  const { selectedPrograms, updateSelectedPrograms } = useSelectedPrograms();
  const { selectedDocuments, updateSelectedDocuments } = useSelectedDocuments();
  const { selectedImages, updateSelectedImages } = useSelectedImages();
  const { attachments, getAttachments, deleteAttachment } = useAttachments();
  const bId = useActiveBrand();
  const programs = attachments.videos || [];
  const documents = attachments.docs || [];
  const images = attachments.images || [];
  const brands = attachments.brands || [];

  const showVideo = React.useCallback(row => () => setState(prev => ({ ...prev, showVideoModal: true, activeVideo: row })), [setState]);

  const showDocument = React.useCallback(row => () => setState(prev => ({ ...prev, showDocumentModal: true, activeDocument: row })), [setState]);

  const showImage = React.useCallback(row => () => setState(prev => ({ ...prev, showImageModal: true, activeImage: row })), [setState]);

  const handleCloseVideoModal = React.useCallback(() => setState(prev => ({ ...prev, showVideoModal: false, activeVideo: null })), [setState]);

  const handleCloseDocumentModal = React.useCallback(() => setState(prev => ({ ...prev, showDocumentModal: false, activeDocument: null })), [setState]);

  const handleCloseImageModal = React.useCallback(() => setState(prev => ({ ...prev, showImageModal: false, activeImage: null })), [setState]);

  const handleProgramSelect = React.useCallback(
    id => event => {
      if (event?.target?.checked) {
        updateSelectedPrograms([...selectedPrograms.filter(c => c.id !== id), programs.find(c => c.id === id)]);
      } else {
        updateSelectedPrograms(selectedPrograms.filter(c => c.id !== id)); // User is de-selecting, always remove
      }
    },
    [selectedPrograms, programs, updateSelectedPrograms]
  );

  const handleDocumentSelect = React.useCallback(
    id => event => {
      if (event?.target?.checked) {
        updateSelectedDocuments([...selectedDocuments.filter(c => c.id !== id), documents.find(c => c.id === id)]);
      } else {
        updateSelectedDocuments(selectedDocuments.filter(c => c.id !== id)); // User is de-selecting, always remove
      }
    },
    [selectedDocuments, documents, updateSelectedDocuments]
  );

  const handleImageSelect = React.useCallback(
    id => event => {
      if (event?.target?.checked) {
        updateSelectedImages([...selectedImages.filter(c => c.id !== id), images.find(c => c.id === id)]);
      } else {
        updateSelectedImages(selectedImages.filter(c => c.id !== id)); // User is de-selecting, always remove
      }
    },
    [selectedImages, images, updateSelectedImages]
  );

  const handleConfirmPrompt = React.useCallback(row => () => setState(prev => ({ ...prev, confirmDelete: true, attachmentToDelete: row })), [setState]);

  const handleConfirmDelete = React.useCallback(
    confirmed => {
      confirmed && attachmentToDelete && deleteAttachment({ id: attachmentToDelete.id, type: attachmentToDelete.type, onSuccess: () => getAttachments() });
      setState(prev => ({ ...prev, confirmDelete: false, attachmentToDelete: null }));
    },
    [getAttachments, setState, attachmentToDelete, deleteAttachment]
  );

  const handleEdit = React.useCallback(row => () => {
    setState(prev => ({ ...prev, showEditModal: true, attachmentToEdit: row }));
  }, [setState]);

  const handleCloseEditModal = React.useCallback((didSave) => () => {
    if (didSave) getAttachments();
    setState(prev => ({ ...prev, showEditModal: false, attachmentToEdit: null }));
  }, [setState, getAttachments]);

  return (
    <div className='app__attachments'>
      <EditAttachmentModal isOpen={showEditModal} attachment={attachmentToEdit} brands={brands} onClose={handleCloseEditModal} />
      {canDelete && <ConfirmDeleteModal isOpen={confirmDelete} onConfirm={handleConfirmDelete} label="attachment" />}
      {activeVideo !== null && (
        <div className={`${!showVideoModal ? 'hidden' : ''}`}>
          <VideoModal handleClose={handleCloseVideoModal} path={activeVideo.path} title={activeVideo.title} />
        </div>
      )}
      {activeDocument !== null && (
        <div className={`${!showDocumentModal ? 'hidden' : ''}`}>
          <DocumentModal handleClose={handleCloseDocumentModal} path={activeDocument.path} title={activeDocument.title} />
        </div>
      )}
      {activeImage !== null && (
        <div className={`${!showImageModal ? 'hidden' : ''}`}>
          <ImageModal handleClose={handleCloseImageModal} path={activeImage.path} title={activeImage.title} />
        </div>
      )}
      <span className='app__attachments__prompt'>
        {canSelect && (
          <p>
            <b>Tap</b> item to view or <b>select</b> the box to attach a document or video. <b>Click 'next'</b> to continue.
          </p>
        )}
        {!canSelect && (
          <div className='app__standalone-header'>
            <h4>Attachment Library</h4>
            <p>These are your saved attachments. You can review them by clicking the thumbnails or the play buttons.</p>
          </div>
        )}
      </span>
      <section className='app__attachments__videos clearfix'>
        {documents.filter(function (doc) {
          return doc.isCustom === '0';
        }).map((row, key) => (
          <div className='app__attachments__video-container' key={'video-' + key}>
            <figure style={textOver}>
              <div
                className='app__attachments__video'
                style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.lpThumb})` }}
                onClick={showDocument(row)}
              ></div>
            </figure>

            <div className='app__attachments__header'>
              <i className='fa fa-play' onClick={showDocument(row)}></i>
              {canSelect && (
                <input type='checkbox' className='checkbox-square' style={{ marginTop: '4px' }} id={'video-check-' + row.id} onChange={handleDocumentSelect(row.id)} checked={selectedDocuments.find(sd => sd.id === row.id) ? true : false} />
              )}
            </div>
            <p className='app__attachments__video-title'>{row.title}</p>
          </div>
        ))}
        {programs.filter(function (program) {
          return program.isCustom === '0';
        }).map((row, key) => (
          <div className='app__attachments__video-container' key={'video-' + key}>
            <figure style={textOver}>
              <div
                className='app__attachments__video'
                style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.lpThumb})` }}
                onClick={showVideo(row)}
              ></div>
              <figcaption style={figCaption}>
                <span>{formatSeconds(row.runtime)}</span>
              </figcaption>
            </figure>

            <div className='app__attachments__header'>
              <i className='fa fa-play' onClick={showVideo(row)}></i>
              {canSelect && (
                <input type='checkbox' className='checkbox-square' style={{ marginTop: '4px' }} id={'video-check-' + row.id} onChange={handleProgramSelect(row.id)} checked={selectedPrograms.find(sp => sp.id === row.id) ? true : false} />
              )}
            </div>
            <p className='app__attachments__video-title'>{row.title}</p>
          </div>
        ))}
      </section>

      {isActiveFeature('custom_library') &&
        <>
          <div className='app__standalone-header'>
            <h4>Custom Library</h4>
          </div>

          <section className='app__attachments__videos clearfix'>

            {documents.filter(function (doc) {
              return doc.isCustom === '1' && (canSelect ? doc.bId === bId : true);
            }).map((row, key) => (
              <div className='app__attachments__video-container' key={'video-' + key}>
                <figure style={textOver}>
                  <div
                    className='app__attachments__video'
                    style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.lpThumb})` }}
                    onClick={showDocument(row)}
                  ></div>
                </figure>

                <div className='app__attachments__header'>
                  <i className='fa fa-play' onClick={showDocument(row)}></i>
                  {canSelect && (
                    <input type='checkbox' className='checkbox-square' style={{ marginTop: '4px' }} id={'video-check-' + row.id} onChange={handleDocumentSelect(row.id)} checked={selectedDocuments.find(sd => sd.id === row.id) ? true : false} />
                  )}
                  {canDelete && (
                    <>
                      <button className='app__button--attachments-delete' onClick={handleEdit(row)}>
                        <i className='fa fa-edit'></i>
                      </button>
                      <button className='app__button--attachments-delete' onClick={handleConfirmPrompt(row)}>
                        <i className='fa fa-trash'></i>
                      </button>
                    </>
                  )}
                </div>
                <p className='app__attachments__video-title'>{row.title}</p>
              </div>
            ))}
            {programs.filter(function (program) {
              return program.isCustom === '1' && (canSelect ? program.bId === bId : true);
            }).map((row, key) => (
              <div className='app__attachments__video-container' key={'video-' + key}>
                <figure style={textOver}>
                  <div
                    className='app__attachments__video'
                    style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.lpThumb})` }}
                    onClick={showVideo(row)}
                  ></div>
                  <figcaption style={figCaption}>
                    <span>{formatSeconds(row.runtime)}</span>
                  </figcaption>
                </figure>

                <div className='app__attachments__header'>
                  <i className='fa fa-play' onClick={showVideo(row)}></i>
                  {canSelect && (
                    <input type='checkbox' className='checkbox-square' style={{ marginTop: '4px' }} id={'video-check-' + row.id} onChange={handleProgramSelect(row.id)} checked={selectedPrograms.find(sp => sp.id === row.id) ? true : false} />
                  )}
                  {canDelete && (
                    <>
                      <button className='app__button--attachments-delete' onClick={handleEdit(row)}>
                        <i className='fa fa-edit'></i>
                      </button>
                      <button className='app__button--attachments-delete' onClick={handleConfirmPrompt(row)}>
                        <i className='fa fa-trash'></i>
                      </button>
                    </>
                  )}
                </div>
                <p className='app__attachments__video-title'>{row.title}</p>
              </div>
            ))}

            {images.filter(function (img) {
              return img.isCustom === '1' && (canSelect ? img.bId === bId : true);
            }).map((row, key) => (
              <div className='app__attachments__video-container' key={'image-' + key}>
                <figure style={textOver}>
                  <div
                    className='app__attachments__video'
                    style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.path})` }}
                    onClick={showImage(row)}
                  ></div>
                </figure>

                <div className='app__attachments__header'>
                  <i className='fa fa-play' onClick={showImage(row)}></i>
                  {canSelect && (
                    <input type='checkbox' className='checkbox-square' style={{ marginTop: '4px' }} id={'video-check-' + row.id} onChange={handleImageSelect(row.id)} checked={selectedImages.find(si => si.id === row.id) ? true : false} />
                  )}
                  {canDelete && (
                    <>
                      <button className='app__button--attachments-delete' onClick={handleEdit(row)}>
                        <i className='fa fa-edit'></i>
                      </button>
                      <button className='app__button--attachments-delete' onClick={handleConfirmPrompt(row)}>
                        <i className='fa fa-trash'></i>
                      </button>
                    </>
                  )}
                </div>
                <p className='app__attachments__video-title'>{row.title}</p>
              </div>
            ))}
          </section>
        </>
      }
    </div>
  );
}
