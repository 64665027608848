import AWS from 'aws-sdk';
import { s3BucketDemo } from '../package.json';

export const Bucket = s3BucketDemo;

export const region = 'us-east-1';
export const accessKeyId = 'AKIA4LP3TPCN3XG3AMW2';
export const secretAccessKey = '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';
export const vocabularyName = 'nubeqa-vocab-table-medical';

AWS.config.update({
  accessKeyId,
  secretAccessKey
});

export const getS3Url = key => key && `https://${Bucket}.s3.${region}.amazonaws.com/${key}`;

export const s3Bucket = new AWS.S3({
  params: { Bucket },
  region
});
