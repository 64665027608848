import React from 'react';
import InputRange from 'react-input-range';
import CountDown from './CountDown';
import VideoPreview from './VideoPreview';
import AutoScrollScript from './AutoScrollScript';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { browserName, isMobile } from 'react-device-detect';
import { Bucket, getS3Url, s3Bucket } from '../awsConfig';
import getBlobDuration, { isEmpty, uuid, validateFilename } from '../utils/helpers';
import { useMediaRecorder } from './useMediaRecorder';
import useScriptDetails from '../store/selectors/useScriptDetails';
import useUser from '../store/selectors/useUser';
import { transcodeLambdaUrl, s3BucketDemo } from '../../package.json';
import { startTranscribeWithStream, stopTranscribe, compareScript } from '../utils/useTranscribe';
import config from 'react-global-configuration';
import parse from 'html-react-parser';
import axios from 'axios';


const minFontSize = 20;
const maxFontSize = 90;

const defaultState = {
  showHints: true,
  videoBlob: null,
  videoPreview: false,
  videoAccepted: false,
  showTranscribeResults: false,
  userCheckedComply: false,
  transcribeDiff: 0,
  transcribeResult: null,
  showVideoIntro: true,
  showCounter: false,
  scrollSpeed: 3,
  fontSize: 32,
  scriptPosition: 0,
  retakeCount: 0,
  makeVideoVisible: false,
  videoTitle: '',
  hcpName: '',
  showErrors: false,
  saving: false,
  errors: {},
  percentageSaved: 0
};

const validate = (hcpName, videoTitle, isPersonalized) => {
  const errors = {};
  if (videoTitle === '') {
    errors['videoTitle'] = 'Title is required';
  } else if (hcpName === '' && isPersonalized) {
    errors['hcpName'] = 'HCP Name is required';
  }
  return errors;
};

async function transcodeVideo(bucketKey) {
  console.log('Transcoding video', { url: `${transcodeLambdaUrl}?bucketName=${s3BucketDemo}&bucketKey=${bucketKey}` });
  const { data } = await axios.get(`${transcodeLambdaUrl}?bucketName=${s3BucketDemo}&bucketKey=${bucketKey}`);
  return data;
}

export default function VideRecorder({ canSaveToGallery, onSave, onBack, isPersonalized }) {
  const [
    {
      videoBlob,
      videoPreview,
      videoAccepted,
      showTranscribeResults,
      userCheckedComply,
      transcribeResult,
      showVideoIntro,
      showCounter,
      scrollSpeed,
      fontSize,
      scriptPosition,
      retakeCount,
      makeVideoVisible,
      videoTitle,
      hcpName,
      errors,
      saving,
      percentageSaved
    },
    setState
  ] = React.useState(defaultState);

  const scriptDetails = useScriptDetails();
  const hideScript = scriptDetails.type === 'none';
  const user = useUser();

  const handleTranscode = React.useCallback(
    key => {
      async function fetchData() {
        try {
          const result = await transcodeVideo(key);

          if (isEmpty(result.Key)) {
            console.error('Transcode error detected', { result });
          } else {
            console.log('Transcode successful', { result });
          }
          setState(prev => ({ ...prev, saving: false }));
        } catch (error) {
          console.error('Error transcoding video', error);
        }
      }

      fetchData();
    },
    [setState]
  );

  const handleSave = React.useCallback(() => {
    // Perform input validation
    const newErrors = validate(hcpName, videoTitle, isPersonalized);
    if (canSaveToGallery && makeVideoVisible && Object.keys(newErrors).length > 0) {
      setState(prev => ({ ...prev, showErrors: true, errors: newErrors })); // Flag errors for display
    } else {
      const videoName = isPersonalized ? hcpName + ', ' + videoTitle : videoTitle;
      setState(prev => ({ ...prev, showErrors: false, errors: {}, saving: true})); // Reset error conditions
      // Save Video to backend, returl ID and URL of hosted Video.
      let $key = `${uuid()}`;
      // Safari and iOS safari/chrome already create mp4 so we'll skip transcoding and save directly to s3 with .mp4 extension
      if (browserName === 'Safari' || (isMobile && browserName === 'Chrome') || config.get('isIQVIA')) {
        $key = `${uuid()}.mp4`
      }

      const params = {
        ACL: 'public-read',
        Body: videoBlob,
        Bucket,
        Key: $key
      };

      console.log('Saving to bucket...', params);

      s3Bucket
        .putObject(params)
        .on('httpUploadProgress', evt => {
          let perc = parseFloat(((evt.loaded / evt.total) * 100).toFixed(0));
          setState(prev => ({ ...prev, percentageSaved: perc }));
          console.log('s3 putObject progress', evt);
        })
        .send(err => {
          if (!err) {
            // pass runtime and "save to gallery" checkbox
            console.log('Successfully saved: ' + getS3Url(params.Key));
            getBlobDuration(videoBlob)
              .then(duration => {
                onSave({
                  key: params.Key,
                  videoName,
                  makeVideoVisible: canSaveToGallery ? makeVideoVisible : false,
                  videoBlob,
                  duration: Math.ceil(duration),
                  onSuccess: () => handleTranscode(params.Key),
                  onError: () => {
                    alert('Error saving video');
                    setState(prev => ({ ...prev, saving: false }));
                  }
                });
              })
              .catch(err => {
                alert('Error retreiving video duration');
              });
          } else {
            alert('Error saving to s3');
          }
        });
    }
  }, [onSave, makeVideoVisible, videoBlob, videoTitle, hcpName, isPersonalized, canSaveToGallery, handleTranscode]);

  const handleSavePrompt = React.useCallback(() => {
    console.log('handleSavePrompt');
    let res = compareScript(user.transcribe_options.exclude_words || "", user.transcribe_options.banned_words || "", user.transcribe_options.banned_phrases || "");
    console.log("Percent the same: ", res);
    if (scriptDetails.type === 'select' && res.percent_same < user.transcribe_options.diff_threshold) {
      // Highlight banned words
      // Highlight different words (if following script)
      setState(prev => ({ ...prev, showTranscribeResults: true, transcribeResult: res }));

    } else {
      setState(prev => ({
        ...prev,
        videoAccepted: true,
        makeVideoVisible: true
      }));
    }

  }, [setState, scriptDetails, user]);

  const handleStop = React.useCallback(
    (url, blob) => {
      console.log('onStop, blob size ' + blob.size, url, blob);

      // Stop transcribing
      setState(prev => ({ ...prev, videoBlob: blob }));
    },
    [setState]
  );

  const { startRecording, stopRecording, mediaBlobUrl, previewStream, clearBlobUrl, status } = useMediaRecorder({
    video: true,
    audio: true,
    onStop: handleStop
  });

  const handleStopRecording = React.useCallback(() => {

    stopRecording();
    if (scriptDetails.type === 'select') {
      stopTranscribe();
    }

  }, [stopRecording, scriptDetails]);

  const handleShowCounter = React.useCallback(
    () =>
      setState(prev => ({
        ...prev,
        showCounter: true,
        showVideoIntro: false
      })),
    [setState]
  );

  const handleStartRecording = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      showCounter: false
    }));

    let stream = startRecording();
    if (scriptDetails.type === 'select') {
      startTranscribeWithStream(scriptDetails.intro + " " + scriptDetails.contentA + " " + scriptDetails.contentB + " " + scriptDetails.closing, stream);
    }
  }, [setState, startRecording, scriptDetails]);

  const handlePreview = React.useCallback(() => setState(prev => ({ ...prev, videoPreview: true })), [setState]);

  const handleRetake = React.useCallback(() => {
    clearBlobUrl();
    setState(prev => ({
      ...prev,
      videoBlob: null,
      showCounter: true,
      showVideoIntro: false,
      userCheckedComply: false,
      showTranscribeResults: false,
      retakeCount: prev.retakeCount + 1
    }));
  }, [clearBlobUrl, setState]);

  const handleTranscribeContinue = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      videoAccepted: true,
    }));
  }, [setState])

  const handleScriptPosition = React.useCallback(() => setState(prev => ({ ...prev, scriptPosition: !prev.scriptPosition })), [setState]);

  const handleChange = React.useCallback(id => event => setState(prev => ({ ...prev, [id]: event?.target?.value, errors: {} })), [setState]);

  const handleChangeCheck = React.useCallback(
    id => event => {
      handleChange(id)({ target: { value: event?.target?.checked } }); // Map checked field to value for checkboxes
    },
    [handleChange]
  );

  const videoComplete = videoBlob !== null && (videoAccepted || showTranscribeResults);
  const videoRecording = status === 'recording';

  React.useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (videoRecording) {
        handleStopRecording();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line
  }, [videoRecording, handleStopRecording]);

  return (
    <div className='app__modal recorder__modal video__recorder'>
      <div className='app__modal__container' style={{ top: scriptPosition ? '-5%' : '34%' }}>
        {showVideoIntro && (
          <div className='record__hints'>
            {!hideScript && (
              <div className='record__hint hint1'>
                <h4>Read your script here</h4>
                <p>
                  Your script will appear in this area at the top of the screen, and will scroll to let you follow along (like a teleprompter). Keep track of
                  time with the timer at the top right.
                </p>
              </div>
            )}

            {!hideScript && (
              <div className='record__hint hint2'>
                <h4>Change speed and font size</h4>
                <p>Use these controls to change the scroll speed and the font size of the script until you are comfortable reading it.</p>
              </div>
            )}

            <div className='record__hint hint3'>
              <h4>Monitor your video here</h4>
              <p>Use this screen to monitor your video as you record it.</p>
            </div>

            <div className='record__hint hint4'>
              <h4>Start recording</h4>
              <p>Once you are ready, click the RECORD button to begin filming your video.</p>
              <p>To stop recording, hit any key on your keyboard.</p>
            </div>
            <button className='app__button record__hint__button' onClick={() => setState(prev => ({ ...prev, showVideoIntro: false }))}>
              Close hints and continue
            </button>
          </div>
        )}

        {!hideScript && (
          <AutoScrollScript
            fontSize={fontSize}
            scrollSpeed={videoRecording ? scrollSpeed * 0.05 : 0}
            scriptPosition={scriptPosition}
            retakeCount={retakeCount}
          />
        )}

        <div className='video__wrapper'>
          <div className='app__modal__back'>
            {!showTranscribeResults &&
              <button className='app__button app__button--video-back' onClick={onBack}>
                <i className='fa fa-repeat'></i> Start Over
              </button>
            }
          </div>
          <div>
            {videoBlob === null && (
              <div style={{ position: 'relative' }}>
                <VideoPreview stream={previewStream} status={status} />
                <div className={'action_record video__recorder__button ' + (videoRecording ? 'square' : 'circle')}>
                  <button onClick={videoRecording ? handleStopRecording : handleShowCounter}>
                    <div className='icon-recording'>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>{' '}
                    {videoRecording ? 'Stop' : 'Record'}
                  </button>
                </div>
                <div className='action_script_position'></div>
                {showCounter && <CountDown onCountEnd={handleStartRecording} />}
              </div>
            )}

            {!hideScript && (
              <div className={`script_controls ${videoComplete ? 'hidden' : ''}`}>
                <h5>Script controls</h5>
                <span>font size</span>

                <InputRange
                  maxValue={maxFontSize}
                  minValue={minFontSize}
                  value={fontSize}
                  onChange={fontSize => setState(prev => ({ ...prev, fontSize }))}
                  onChangeComplete={fontSize => console.log(fontSize)}
                />

                <span>Scroll speed</span>
                <InputRange maxValue={20} minValue={0} value={scrollSpeed} onChange={scrollSpeed => setState(prev => ({ ...prev, scrollSpeed }))} />
                <button onClick={handleScriptPosition}>{`Move Script ${scriptPosition ? 'Up' : 'Down'}`}</button>
              </div>
            )}

            {videoBlob !== null && !videoPreview && (
              <div>
                {!videoComplete && <video src={mediaBlobUrl} />}

                {!videoComplete && (
                  <div className='action_buttons'>
                    <button className='gif__review-button' onClick={handlePreview}>
                      <i className='fa fa-play'></i>Preview
                    </button>
                    <button className='gif__review-button' onClick={handleSavePrompt}>
                      <i className='fa fa-check'></i>Accept
                    </button>{' '}
                    <button className='gif__review-button' onClick={handleRetake}>
                      <i className='fa fa-circle'></i>Retake
                    </button>
                  </div>
                )}
              </div>
            )}

            {videoBlob !== null && videoPreview && (
              <div>
                <video
                  src={mediaBlobUrl}
                  onEnded={() => {
                    setState(prev => ({ ...prev, videoPreview: false }));
                  }}
                  controls
                  autoPlay
                />
              </div>
            )}
          </div>
        </div>

        {scriptDetails.type === 'select' && showTranscribeResults && !videoAccepted && (
          <div className='record__complete--video transcription'>
            <div className='record__complete__header'>
              <h4>Transcription results</h4>
            </div>
            <div className='record__complete__body'>
              <p>Here is the script compared with the transcription results of your recording:</p>
              <div className="transcribe__result_container">
                <div className="transcribe__result">{transcribeResult.norm_script}</div>
                <div className="transcribe__result">{parse(transcribeResult.highlighted_transcript)}</div>
              </div>
              {!transcribeResult.has_banned &&
                <p className="transcribe_has_banned"><span><input type="checkbox" name="script_comply" onClick={(e) => {
                  setState(prev => ({ ...prev, userCheckedComply: e.target.checked }));
                }} /></span>I attest that I have adhered to AVEO's standard FOTIVDA promotional guidelines.</p>
              }
              {transcribeResult.has_banned &&
                <p className="transcribe_has_banned">Your transcript contains banned words. Please retake and exclude those words.</p>
              }
              <div className="video__complete__button-row">
                <button className="app__button app__button--modal" onClick={handleRetake}>Retake</button>
                {userCheckedComply && !transcribeResult.has_banned && (
                  <button className="app__button app__button--modal app__button--accent" onClick={handleTranscribeContinue}>Yes, Continue</button>
                )}
              </div>
            </div>
          </div>
        )}

        {videoComplete && videoAccepted && (
          <div className='record__complete--video'>
            <div className='record__complete__header'>
              <h4>Your video is complete!</h4>
            </div>
            <video
              className='video_preview'
              src={mediaBlobUrl}
              onEnded={() => {
                setState(prev => ({ ...prev, videoPreview: false }));
              }}
            />
            <div className='record__complete__body'>
              {!saving && (
                <>
                  Great job!
                  <br />
                  <br />
                  {canSaveToGallery && 
                    <>
                      <input type='checkbox' checked={makeVideoVisible} onChange={handleChangeCheck('makeVideoVisible')}></input>{' '}
                      <label>Save to the video library</label><br>
                      </br>
                      {makeVideoVisible && (
                        <p>Name and save your video to the video library.</p>
                      )}
                    </>
                  }
                </>
              )}

              {saving && (
                <>
                  <div className='record__saving_indicator'>
                    <CircularProgressbar value={percentageSaved} text={`${percentageSaved}%`} styles={buildStyles({
                      textColor: "white",
                      pathColor: "white",
                      trailColor: "#199dde"
                    })} />
                  </div>
                </>
              )}
            </div>
            <div className='record__complete__bottom'>
              {canSaveToGallery && (
                <>
                  {makeVideoVisible && (
                    <>
                      {isPersonalized && (
                        <input
                          type='text'
                          placeholder='Enter HCP Name'
                          className='record__video__title'
                          onChange={handleChange('hcpName')}
                          value={hcpName}
                        ></input>
                      )}
                      <input
                        type='text'
                        placeholder='Enter a video title'
                        className='record__video__title'
                        onChange={handleChange('videoTitle')}
                        value={videoTitle}
                      ></input>

                      <br />
                      {Object.keys(errors).map(k => (
                        <div id={k} key={k} style={{ color: 'red', paddingTop: 8, paddingBottom: 8 }}>
                          {errors[k]}
                          <br />
                        </div>
                      ))}
                      <span>
                        <small>
                          *Note: Titles are for internal use in the gallery only; they are never displayed in the actual emails, or anywhere else on the app.
                        </small>
                      </span>
                    </>
                  )}
                </>
              )}
              <br />
              <div className='video__complete__button-row'>
                <button className='app__button app__button--modal' disabled={saving} onClick={onBack}>
                  Start Over
                </button>
                <button className='app__button app__button--modal app__button--accent' disabled={saving} onClick={handleSave}>
                  {canSaveToGallery && makeVideoVisible ? `Save & Continue` : 'Continue'}
                </button>
              </div>
              <div style={{ height: 0, clear: 'both' }}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
