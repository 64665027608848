import React from 'react';
import { useVideos } from '../api/useVideos';
import useUpdateSelectedVideo from '../store/actions/useUpdateSelectedVideo';
import useUpdateSelectedTemplateId from '../store/actions/useUpdateSelectedTemplateId';
import useUpdateActiveBrandId from '../store/actions/useUpdateActiveBrandId';
import useUpdateSelectedPrograms from '../store/actions/useUpdateSelectedPrograms';
import useUpdateSelectedDocuments from '../store/actions/useUpdateSelectedDocuments';
import useUpdateSelectedImages from '../store/actions/useUpdateSelectedImages';
import useSelectedVideo from '../store/selectors/useSelectedVideo';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import VideoModal from './VideoModal';
import useUpdateScriptOptions from '../store/actions/useUpdateScriptOptions';
import { defaultScriptDetails } from '../store/reducers/layout';
import { isActiveFeature } from '../utils/helpers';

const textOver = {
  position: 'relative',
  margin: 0
};
const figCaption = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  boxSizing: 'border-box',
  minWidth: 60,
  height: 30,
  margin: 19,
  marginLeft: 8,
  marginBottom: 8,
  fontSize: 14,
  fontWeight: 700,
  paddingTop: 4,
  color: 'white',
  textAlign: 'center',
  background: '#343434'
};

const formatSeconds = seconds => {
  const mins = String(Math.floor(seconds / 60)).padStart(2, '0');
  const secs = String(seconds - mins * 60).padStart(2, '0');
  return `${mins}:${secs}`;
};

export default function VideoGallery({ canSelect = false, canDelete = false }) {
  const [{ activeVideo, showVideoModal, confirmDelete, videoToDelete }, setState] = React.useState({
    activeVideo: null,
    showVideoModal: false,
    confirmDelete: false,
    videoToDelete: null
  });
  const updateSelectedVideo = useUpdateSelectedVideo();
  const updateScriptOptions = useUpdateScriptOptions();
  const updateSelectedDocuments = useUpdateSelectedDocuments();
  const updateSelectedPrograms = useUpdateSelectedPrograms();
  const updateSelectedImages = useUpdateSelectedImages();
  const updateSelectedTemplateId = useUpdateSelectedTemplateId();
  const updateActiveBrandId = useUpdateActiveBrandId();
  const selectedVideo = useSelectedVideo();
  const { videos, getVideos, deleteVideo } = useVideos();

  React.useEffect(() => {
    if(!canSelect) {
      updateSelectedVideo(null);
      updateScriptOptions(defaultScriptDetails);
    }

    updateSelectedDocuments([]);
    updateSelectedPrograms([]);
    updateSelectedImages([]);
  }, []);
  

  const showVideo = React.useCallback(id => () => setState(prev => ({ ...prev, showVideoModal: true, activeVideo: id })), [setState]);

  const handleCloseVideoModal = React.useCallback(() => setState(prev => ({ ...prev, showVideoModal: false, activeVideo: null })), [setState]);
  
  const handleChange = React.useCallback((id, templateId) => event => {
    const selectedVideo = videos.filter(video => video.id === id);
    updateSelectedVideo(event?.target?.checked ? id : null);
    updateSelectedTemplateId(selectedVideo[0].templateId);
  },
  [updateSelectedVideo, updateSelectedTemplateId, updateActiveBrandId, videos]);

  const handleConfirmPrompt = React.useCallback(id => () => setState(prev => ({ ...prev, confirmDelete: true, videoToDelete: id })), [setState]);

  const handleConfirmDelete = React.useCallback(
    confirmed => {
      confirmed && videoToDelete && deleteVideo({ id: videoToDelete, onSuccess: () => getVideos() });
      setState(prev => ({ ...prev, confirmDelete: false, videoToDelete: null }));
    },
    [getVideos, setState, videoToDelete, deleteVideo]
  );

  return (
    <div className='app__attachments video__gallery'>
      {canDelete && <ConfirmDeleteModal isOpen={confirmDelete} onConfirm={handleConfirmDelete} />}
      {activeVideo !== null && (
        <div className={`${!showVideoModal ? 'hidden' : ''}`}>
          <VideoModal handleClose={handleCloseVideoModal} path={videos[activeVideo].path} title={videos[activeVideo].title} />
        </div>
      )}
      <span className='app__attachments__prompt'>
        {canDelete && (
          <div className='app__standalone-header'>
            <h4>Video Library</h4>
            <p>These are your saved videos. You can review them by clicking the thumbnails or the play buttons.</p>
            <p>Videos you no longer need can be deleted by using the trash button on each video.</p>
          </div>
        )}
      </span>
      {isActiveFeature('split_video_gallery') &&
        <div className="app__subheader__tabs video__tabs">
          {canSelect && (
          <p>
            <b>Tap</b> to view or <b>select</b> the box to attach your video. Click <b>next</b> to attach additional assets.
          </p>
        )}
        </div>
      }
      <section className='app__attachments__videos clearfix'>
        {videos.map((row, key) => (
          <div className='app__attachments__video-container' key={'video-' + key}>
            <figure style={textOver}>
              <div
                className='app__attachments__video'
                style={{ minHeight: 250, minWidth: 250, backgroundImage: `url(${row.gif})` }}
                onClick={showVideo(key)}
              ></div>
              {row.runtime && (
                <figcaption style={figCaption}>
                  <span>{formatSeconds(row.runtime)}</span>
                </figcaption>
              )}
            </figure>

            <div className='app__attachments__header'>
              <i className='fa fa-play' onClick={showVideo(key)}></i>
              {canSelect && (
                <input
                  type='checkbox'
                  className='checkbox-square'
                  style={{ marginTop: '4px' }}
                  id={'video-check-' + row.id}
                  checked={selectedVideo === row.id}
                  onChange={handleChange(row.id, row.templateId)}
                />
              )}
              {canDelete && (
                <button className='app__button--attachments-delete' onClick={handleConfirmPrompt(row.id)}>
                  <i className='fa fa-trash'></i>
                </button>
              )}
            </div>
            <p className='app__attachments__video-title'>{row.title}</p>
            <p className='app__attachments__video-dateadded'>{row.dateadded}</p>
          </div>
        ))}
      </section>
    </div>
  );
}
