import React from 'react';
import Webcam from 'react-webcam';
import useUpdateUserPhoto from '../api/useUpdateUserPhoto';

const videoConstraints = {
  facingMode: 'user'
};

export default function PhotoCapture({ onPhotoUpdate }) {
  const [{ screenshot, error, isLive }, setState] = React.useState({
    screenshot: null,
    error: '',
    isLive: true
  });

  const webcamRef = React.useRef(null);

  const handleClick = React.useCallback(() => setState(prev => ({ ...prev, screenshot: webcamRef.current.getScreenshot(), isLive: false })), [setState]);
  const { updateUserPhoto } = useUpdateUserPhoto();

  const handleSave = React.useCallback(
    () =>
      updateUserPhoto({
        screenshot,
        onSuccess: response => {
          if (response.data.status === 'false' || response.data.status === 'fail') {
            setState(prev => ({ ...prev, error: 'Error updating photo' }));
          } else {
            setState(prev => ({ ...prev, error: 'Photo successfully updated.' }));
            onPhotoUpdate(response.data.result);
          }
        },
        onError: () => {
          setState(prev => ({ ...prev, error: 'Error updating photo' }));
        }
      }),
    [screenshot, updateUserPhoto, onPhotoUpdate]
  );

  return (
    <div className='app__video'>
      <div className='app__half-column' style={{ width: '60%' }}>
        <section className='app__video-capture'>
          {screenshot === null && (
            <div className='app__capture-frame'>
              <img alt='capture' className='app__capture-target' src='img/axon__target.png' />
              <Webcam ref={webcamRef} audio={false} screenshotFormat='image/jpg' width={400} videoConstraints={videoConstraints} />
            </div>
          )}
          {screenshot !== null && <img className="app__capture-frame app__capture-frame--padded" src={screenshot} alt='Red dot' />}
        </section>
      </div>
      <div className='app__half-column app__profile__photo-text' style={{ width: '39%' }}>
        <p>
          Your profile picture and contact information will appear in emails and landing pages for the REPCast program. Any changes 
          or updates made on this page are reflected in this program only.
        </p>
        <div className='app__video-buttons'>
          {isLive ? (
            <button onClick={handleClick} className='app__profile__button photocapture'>
              Capture Photo
            </button>
          ) : (
            <button onClick={() => setState(prev => ({ ...prev, screenshot: null, isLive: true }))} className='app__profile__button photocapture'>
              Retake
            </button>
          )}
          <button onClick={handleSave} className='app__profile__button photocapture' disabled={isLive}>
            SAVE
          </button>
          <p className='error'>{error}</p>
        </div>
      </div>
    </div>
  );
}
