import React from 'react';
import useUser from '../store/selectors/useUser';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useSelectedTemplateId from '../store/selectors/useSelectedTemplateId';
import useApiPost from './useApiPost';
import { useDeleteAttachment } from './useDeleteAttachment';

const buildFormData = (user, bId, tId, includeTemplate) => {
  let formData = new FormData();
  formData.set('hID', 'getBrandAttachments');
  formData.set('uID', user.id);
  if(includeTemplate) {
    formData.set('tID', tId);
  }
  formData.set('bID', bId);
  return formData;
};

export const useAttachments = ({ autoLoad = true } = {}, includeTemplate = false) => {
  console.log("includeTemplate ? " + includeTemplate);
  const user = useUser();
  const bId = useActiveBrand();
  const tId = useSelectedTemplateId();
  const { data, loading, handlePost } = useApiPost();
  const { deleteAttachment } = useDeleteAttachment();

  const getAttachments = React.useCallback(props => handlePost({ data: buildFormData(user, bId, tId, includeTemplate), ...props }), [bId, user, handlePost]);

  React.useEffect(() => {
    autoLoad && getAttachments();
  }, [autoLoad, getAttachments]);

  return { attachments: data, loading, getAttachments, deleteAttachment };
};