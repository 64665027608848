import {
  RESET_STATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_SELECTED_CONTACTS,
  UPDATE_SELECTED_VIDEO,  
  UPDATE_SCRIPT_OPTIONS,
  UPDATE_LAYOUT,
  UPDATE_SELECTED_PROGRAMS,
  UPDATE_SELECTED_DOCUMENTS,
  UPDATE_SELECTED_IMAGES,
  UPDATE_SELECTED_TEMPLATE_ID,
  UPDATE_ACTIVE_BRAND_ID,
  UPDATE_USER_CONTACTS // Salesforce Contacts
} from '../constants/action-types';

export function resetState(payload) {
  return { type: RESET_STATE, payload };
}

export function loginUser(payload) {
  return { type: LOGIN, payload };
}

export function logoutUser(payload) {
  return { type: LOGOUT, payload };
}

export function updateUser(payload) {
  return { type: UPDATE_USER, payload };
}

export function updateSelectedContacts(payload) {
  return { type: UPDATE_SELECTED_CONTACTS, payload };
}

export function updateSelectedVideo(payload) {
  return { type: UPDATE_SELECTED_VIDEO, payload };
}

export function updateScriptOptions(payload) {
  return { type: UPDATE_SCRIPT_OPTIONS, payload };
}

export function updateLayout(payload) {
  return { type: UPDATE_LAYOUT, payload };
}

export function updateSelectedPrograms(payload) {
  return { type: UPDATE_SELECTED_PROGRAMS, payload };
}

export function updateSelectedDocuments(payload) {
  return { type: UPDATE_SELECTED_DOCUMENTS, payload };
}

export function updateSelectedImages(payload) {
  return { type: UPDATE_SELECTED_IMAGES, payload };
}

export function updateSelectedTemplateId(payload) {
  return { type: UPDATE_SELECTED_TEMPLATE_ID, payload };
}

export function updateActiveBrandId(payload) {
  return { type: UPDATE_ACTIVE_BRAND_ID, payload };
}

export function updateUserContacts(payload) {
  return { type: UPDATE_USER_CONTACTS, payload };
}