import config from "react-global-configuration";

export default function initConfig(env = "local", brand = "iqvia_aveo") {

  if (env === "local") {
    config.set(
      {
        apiUrl: "http://repcaststudio:8888/api/",
        brandName: "repcast",
        twilioNumber: "",
        loginToken: "68303a6d-19f9-4214-b7c5-6d3941203944",
        isIQVIA: false
      },
      { freeze: false }
    );
  } else if (env === "development") {
    // Use for non-prod tech ops environments
    config.set(
      {
        apiUrl: "/web/api/",
        brandName: "repcast",
        twilioNumber: "",
        loginToken: "68303a6d-19f9-4214-b7c5-6d3941203944",
        isIQVIA: false
      },
      { freeze: false }
    );
  } else if (env === "production") {
    config.set(
      {
        apiUrl: "/web/api/",
        brandName: "repcast",
        twilioNumber: "",
        loginToken: "68303a6d-19f9-4214-b7c5-6d3941203944",
        isIQVIA: false // Production is currently web - flip this for production IQVIA
      },
      { freeze: false }
    );
  } else {
    throw new Error("Unknown environment, failed to initialize config.");
  }
}

