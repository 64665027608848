import React from 'react';

export default function EmailComposerSelectType({ onSelect }) {
  return (
    <div id='recipientsContainer' className={`selectRecipientsContainer`}>
      <div className='app__standalone-header'>
        <h4>Send Email</h4>
      </div>
      <div className='app__button-container--large'>
        <div className='app__button-div--large'>
          <button onClick={onSelect(false)} className='app__button app__button--large app__button app__button--personalized'></button>
          <span>Create</span>
        </div>
      </div>
    </div>
  );
}
