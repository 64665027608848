import React from 'react';
import useUser from '../store/selectors/useUser';
import useContacts from '../api/useContacts';
import useSelectedContacts from '../store/selectors/useSelectedContacts';
import useUpdateUserContacts from '../store/actions/useUpdateUserContacts';
import { isEmpty, sortAscending, sortDescending } from '../utils/helpers';
import useSelectEmail from '../store/actions/useSelectEmail';
import useResetState from '../store/actions/useResetState';
import CustomContactSelect from './CustomContactSelect';
import Loader from 'react-loader-spinner';
import { isActiveFeature } from '../utils/helpers.js';

const columns = [
  { id: 'firstName', label: 'F Name', width: '150', canSort: true },
  { id: 'lastName', label: 'L Name', width: '150', canSort: true },
  { id: 'email', label: 'Email Address' },
  { id: 'phone', label: 'Phone' },
  { id: 'practice', label: 'Practice', width: '150', canSort: true },
  { id: 'city', label: 'City', width: '120', canSort: true },
  { id: 'state', label: 'State', width: '50', canSort: true }
];

const RowInput = ({ handleSelect, unsubscribed, unsubscribedNpi, email, id, type, checked }) => {
  if (unsubscribed === true || unsubscribedNpi === true) {
    return <input type='checkbox' checked={false} disabled className='checkbox-square has-optout' id={type + '-' + id} />;
  } else if (email === '') {
    return <input type='checkbox' checked={false} disabled className='checkbox-square is-empty' id={type + '-' + id} />;
  } else {
    return <input onChange={handleSelect(id)} checked={checked} type='checkbox' className='checkbox-square' id={type + '-' + id} />;
  }
};

const EmailSelect = ({ row, selectedContacts, isSelected, onSelect }) => {
  const options = ['email', 'hcp_email_1', 'hcp_email_2', 'hcp_email_3', 'hcp_email_4']
    .filter(fieldName => fieldName === 'email' || !isEmpty(row[fieldName]))
    .map(fieldName => ({
      label: row[fieldName],
      value: fieldName
    }));

  return <CustomContactSelect options={options} onSelect={onSelect} contact={row} />;
};

const PhoneNumberSelect = ({ row, selectedContacts, isSelected, onSelect }) => {
  const options = ['hcp_phone', 'hcp_phone_1', 'hcp_phone_2', 'hcp_phone_3', 'hcp_phone_4']
    .filter(fieldName => row[fieldName] !== '0' && !isEmpty(row[fieldName]))
    .map(fieldName => ({
      label: row[fieldName],
      value: fieldName
    }));

  return <CustomContactSelect options={options} onSelect={onSelect} contact={row} />;
};

export default function Recipients({ singleSelect, profileMode }) {
  const [{ sortBy, sortDir, searchTerm, showContactCardModal, showAddContactModal, editingContact, page, pageSize, didFetch }, setState] = React.useState({
    searchTerm: '',
    sortBy: 'firstName',
    sortDir: 'asc',
    showContactCardModal: true,
    showAddContactModal: false,
    editingContact: null,
    page: 0,
    pageSize: 10,
    didFetch: false
  });

  const user = useUser();
  const updateUserContacts = useUpdateUserContacts();
  const { selectedContacts, updateSelectedContacts } = useSelectedContacts();
  const { data: contacts, handleRefresh, setContacts } = useContacts();
  const selectEmail = useSelectEmail();
  const resetState = useResetState();

  // temp
  const loading = false;

  // Load initial contacts from salesforce if array is empty
  if (!contacts || (contacts.length === 0 && !didFetch && !loading)) {
    console.log('refresh');
    setState(prev => ({ ...prev, didFetch: true }));
    handleRefresh({
      onSuccess: response => {
        contacts = response.data.result;
        updateUserContacts(response.data.result);
      }
    });
  }

  const handleRefreshContacts = React.useCallback(
    () =>
      handleRefresh({
        onSuccess: response => {
          contacts = response.data.result;
          updateUserContacts(response.data.result);
        }
      }),
    [contacts, updateUserContacts, handleRefresh]
  );

  const handleSearchTerm = React.useCallback(
    event => setState(prev => ({ ...prev, searchTerm: event?.target?.value, page: 0 })), // Reset page when search term is updated
    [setState]
  );

  const handleSelect = React.useCallback(
    id => event => {
      if (event?.target?.checked) {
        if (singleSelect) {
          updateSelectedContacts([contacts.find(c => c.id === id)]);
        } else {
          updateSelectedContacts([...selectedContacts.filter(c => c.id !== id), contacts.find(c => c.id === id)]);
        }
      } else {
        updateSelectedContacts(selectedContacts.filter(c => c.id !== id)); // User is de-selecting, always remove
      }
    },
    [selectedContacts, contacts, singleSelect, updateSelectedContacts]
  );

  const filtered = (
    isEmpty(searchTerm)
      ? contacts
      : contacts.filter(
          ({
            firstName,
            lastName,
            email,
            city,
            state,
            zip,
            hcp_email_1,
            hcp_email_2,
            hcp_email_3,
            hcp_email_4,
            hcp_phone,
            hcp_phone_1,
            hcp_phone_2,
            hcp_phone_3,
            hcp_phone_4
          }) =>
            [
              firstName,
              lastName,
              email,
              city,
              state,
              zip,
              hcp_email_1,
              hcp_email_2,
              hcp_email_3,
              hcp_email_4,
              hcp_phone,
              hcp_phone_1,
              hcp_phone_2,
              hcp_phone_3,
              hcp_phone_4
            ].reduce((isMatch, searchText) => isMatch || (!isEmpty(searchText) && searchText.toLowerCase().includes(searchTerm.toLowerCase())), false)
        )
  ).sort(sortDir === 'asc' ? sortAscending(sortBy) : sortDescending(sortBy));

  const allSelected = filtered.reduce((allTrue, c) => (allTrue && selectedContacts.find(sc => sc.id === c.id) ? true : false), true);

  const handleSelectAll = React.useCallback(() => updateSelectedContacts(allSelected ? [] : filtered), [allSelected, filtered, updateSelectedContacts]);

  const handleSortBy = React.useCallback(
    sortBy => () => setState(prev => ({ ...prev, sortBy, sortDir: prev.sortBy === sortBy ? (prev.sortDir === 'asc' ? 'desc' : 'asc') : prev.sortDir })),
    [setState]
  );

  const onCloseContactCardModal = React.useCallback(
    (didSave, updatedContact) => {
      if (didSave) {
        // Contact change, update both contact and selected stores
        let newContacts = [...contacts];
        let newSelected = [...selectedContacts];

        const contactIndex = newContacts.findIndex(e => e.id === updatedContact.id);
        const selectedIndex = newSelected.findIndex(e => e.id === updatedContact.id);

        if (contactIndex >= 0) {
          newContacts[contactIndex] = updatedContact;
        }

        if (selectedIndex >= 0) {
          newSelected[selectedIndex] = updatedContact;
        }

        // If the user had called a Save action on the Contact Card, warn of DB overwrites
        setContacts(newContacts);
        updateSelectedContacts(newSelected);
      }
      setState(prev => ({ ...prev, showContactCardModal: false, editingContact: null }));
    },
    [contacts, selectedContacts, setState, updateSelectedContacts, setContacts]
  );

  const onCloseAddContactModal = React.useCallback(
    (didSave, newContact) => {
      if (didSave) {
        let newContacts = [...contacts];
        newContacts.push(newContact);
        setContacts(newContacts);
      }
      setState(prev => ({ ...prev, showAddContactModal: false }));
    },
    [contacts, setState, setContacts]
  );

  const selectDropdownEmail = React.useCallback(
    (value, contact) => {
      !profileMode &&
        selectEmail({
          contactId: contact.id,
          fieldName: value
        });
    },
    [profileMode, selectEmail]
  );

  // The backend will distinguish whether an email OR SMS is being sent out
  // Thus we're sending an email address OR a text message from the front end
  // as the chosen value
  const selectDropdownPhone = React.useCallback(
    (value, contact) => {
      !profileMode &&
        selectEmail({
          contactId: contact.id,
          fieldName: value
        });
    },
    [profileMode, selectEmail]
  );

  const handlePageSize = React.useCallback(e => setState(prev => ({ ...prev, page: 0, pageSize: Number(e.target.value) })), [setState]);
  const handlePage = React.useCallback(page => () => setState(prev => ({ ...prev, page })), [setState]);

  const pageCount = Math.ceil(filtered.length / pageSize);

  const handlePageInput = React.useCallback(
    e => {
      const page = e.target.value ? Number(e.target.value) - 1 : 0;
      if (page >= pageCount) {
        setState(prev => ({ ...prev, page: pageCount - 1 }));
      } else if (page < pageCount && page > 0) {
        setState(prev => ({ ...prev, page }));
      } else {
        setState(prev => ({ ...prev, page: 0 }));
      }
    },
    [setState, pageCount]
  );

  const canPreviousPage = page > 0;
  const canNextPage = page < pageCount;
  const paged = filtered.length > pageSize ? filtered.filter((r, i) => i >= pageSize * page && i <= pageSize * (page + 1) - 1) : filtered;

  return (
    <section className='app__recipients'>
      {/* {showContactCardModal && editingContact !== null && (
        <ContactModal uId={user.id} handleClose={onCloseContactCardModal} contact={editingContact} profileMode={profileMode ? true : false} />
      )}

      {showAddContactModal && (
        <AddContactModal uId={user.id} handleClose={onCloseAddContactModal} profileMode={profileMode ? true : false} />
      )} */}

      <div className='app__recipients__container'>
        <div className='app__half-boxes'>
          <div className='app__half-box text-left clearfix'>
            <h4 className='app__recipients__heading'>
              <i className='fa fa-search'></i> Search
            </h4>
            <input
              type='text'
              className='app__recipients-input'
              placeholder='[Dr. First Name, Last Name] ; [Dr. First Name, Last Name]'
              onChange={handleSearchTerm}
            />
          </div>
          <div className='app__half-box text-left clearfix'>
            <h4 className='app__recipients__heading' style={{ marginLeft: '9%', display: profileMode ? 'none' : 'block' }}>
              <i className='fa fa-envelope'></i> Selected Recipients ({selectedContacts.length})
            </h4>
            <div className='app__recipients-selected' style={{ display: profileMode ? 'none' : 'block' }}>
              <div className='app__recipients-selected__list clearfix' id='selectedContacts'>
                {selectedContacts.map((row, key) => (
                  <span key={'contact-' + key}>{row.toEmail}, </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='clearfix'></div>
        <div className='app__recipients__list'>
          {/* <div className='app__recipients-actions'>
            <button className='app__button app__contact-oce' onClick={handleRefreshContacts}>
              Display Contacts from OCE
            </button>
          </div> */}
          <div className='app__recipients__commands'>
            <div className={`${profileMode ? 'section-3-profile' : 'section-3'}`}></div>
          </div>

          {loading && (
            <div className='app__recipients activity-loader'>
              <Loader type='ThreeDots' color='#199dde' height={200} width={200} timeout={300000} />
            </div>
          )}

          {!loading && (
            <table className='app__recipients__list-table'>
              <tbody>
                <tr>
                  {!profileMode && (
                    <th width='62' className='app__recipients__select-all' onClick={!singleSelect ? handleSelectAll : undefined}>
                      {!singleSelect && <span>{allSelected === true ? 'Deselect All' : 'Select All'}</span>}
                    </th>
                  )}

                  {columns.map(({ id, label, width = '', canSort }) => {
                    if (!isActiveFeature('sms_sends') && label === 'Phone') {
                      return null;
                    } else {
                      return (
                        <th key={id} width={width} className='asc' onClick={canSort ? handleSortBy(id) : undefined}>
                          <span>
                            {label}
                            {canSort && <i className='fa fa-sort' />}
                          </span>
                        </th>
                      );
                    }
                  })}
                </tr>
                {paged.map((row, key) => (
                  <tr key={key} className={`${row.unsubscribed ? 'has-optout' : row.email === '' ? 'is-empty' : ''}`}>
                    {!profileMode && (
                      <td
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <RowInput
                          handleSelect={handleSelect}
                          unsubscribed={row.unsubscribed}
                          unsubscribedNpi={row.unsubscribedNpi}
                          email={row.email}
                          id={row.id}
                          type='contact'
                          checked={selectedContacts.find(sc => sc.id === row.id) ? true : false}
                        />
                      </td>
                    )}
                    <td>{row.firstName}</td>
                    <td>{row.lastName}</td>
                    <td
                      className={
                        selectedContacts.find(
                          sc =>
                            sc.id === row.id &&
                            (sc.toEmail === row.email ||
                              sc.toEmail === row.hcp_email_1 ||
                              sc.toEmail === row.hcp_email_2 ||
                              sc.toEmail === row.hcp_email_3 ||
                              sc.toEmail === row.hcp_email_4)
                        ) && isActiveFeature('sms_sends')
                          ? 'active'
                          : ''
                      }
                    >
                      <EmailSelect
                        row={row}
                        selectedContacts={selectedContacts}
                        isSelected={selectedContacts.find(sc => sc.id === row.id) ? true : false}
                        onSelect={selectDropdownEmail}
                      />
                    </td>
                    {isActiveFeature('sms_sends') && (
                      <td
                        className={
                          selectedContacts.find(
                            sc =>
                              sc.id === row.id &&
                              (sc.toEmail === row.hcp_phone ||
                                sc.toEmail === row.hcp_phone_1 ||
                                sc.toEmail === row.hcp_phone_2 ||
                                sc.toEmail === row.hcp_phone_3 ||
                                sc.toEmail === row.hcp_phone_3)
                          )
                            ? 'active'
                            : ''
                        }
                      >
                        <PhoneNumberSelect
                          row={row}
                          selectedContacts={selectedContacts}
                          isSelected={selectedContacts.find(sc => sc.id === row.id) ? true : false}
                          onSelect={selectDropdownPhone}
                        />
                      </td>
                    )}
                    <td>{row.practice}</td>
                    <td>{row.city}</td>
                    <td>{row.state}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className='pagination' style={{ textAlign: 'left' }}>
            <button onClick={handlePage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={handlePage(page - 1)} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={handlePage(page + 1)} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button onClick={handlePage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
            <span>
              Page {page + 1} of {pageCount}{' '}
            </span>
            <span>
              | Go to page: <input type='number' defaultValue={page + 1} onChange={handlePageInput} />
            </span>{' '}
            <select value={pageSize} onChange={handlePageSize}>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </section>
  );
}
