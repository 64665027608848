import React, { Component } from "react";
import GridCell from "../components/GridCell";
import config from "react-global-configuration";
import axios from "axios";
import { connect } from "react-redux";
import { updateScriptOptions, updateSelectedTemplateId, updateActiveBrandId } from "../store/actions/index";

class ScriptGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allGrids: [],
      gridItems: [],
      topics: [],
      intro: "",
      contentA: "",
      contentB: "",
      closing: "",
      totalRows: 0,
      personalized: false,
      brandId: 0,
      activeTab: 0
    };
  }

  componentDidMount(prevProps) {
    this.setState({ personalized: this.props.personalized, brandId: this.props.brandId });
    this.getScriptGrid(this.props.personalized, this.props.brandId);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.personalized !== this.state.personalized && this.props.personalized !== undefined) || this.props.brandId !== this.state.brandId && this.props.brandId !== undefined) {
      this.setState({ personalized: this.props.personalized, brandId: this.props.brandId }, () => {
        this.getScriptGrid(this.props.personalized, this.props.brandId);
      })
    }
  }

  getScriptGrid = (isPersonalized = false, brandId = 1) => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getScriptGrid");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", brandId);
    bodyFormData.set("isPersonalized", isPersonalized ? 1 : 0);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // unselect all
        const selectedEls = document.querySelectorAll(
          ".app__flex-table__cell--selected"
        );
        for (const el of selectedEls) {
          el.classList.remove("app__flex-table__cell--selected");
        }

        let topics = [];
        for (const [key] of Object.entries(response.data.result)) {
          topics.push(key);
        }

        let result = response.data.result;
        let defaultGrid = result[Object.keys(result)[0]] || [];
        that.props.updateSelectedTemplateId(defaultGrid.templateId);

        let maxLen = defaultGrid["intro"].length;
        if (defaultGrid["contentA"].length > maxLen) {
          maxLen = defaultGrid["contentA"].length;
        }
        if (defaultGrid["contentB"].length > maxLen) {
          maxLen = defaultGrid["contentB"].length;
        }
        if (defaultGrid["closing"].length > maxLen) {
          maxLen = defaultGrid["closing"].length;
        }

        that.setState({
          allGrids: result,
          gridItems: defaultGrid,
          topics: topics,
          templateId: defaultGrid.templateId,
          intro: "",
          contentA: "",
          contentB: "",
          closing: "",
          totalRows: maxLen,
        });
      })
      .catch(function (response) {
      });
  };

  handleTabSelect = (row, key) => {
    if(key === this.state.activeTab) {
      return;
    }

    // this.setState({ gridItems: this.state.allGrids[row], activeTab: key });
    console.log("script updateSelectedTemplateId " + this.state.allGrids[row].templateId);
    this.props.updateSelectedTemplateId(this.state.allGrids[row].templateId);
    this.setState({
      gridItems: this.state.allGrids[row],
      activeTab: key,
      intro: "",
      contentA: "",
      contentB: "",
      closing: "",
    });
    let details = {
      intro: "",
      contentA: "",
      contentB: "",
      closing: "",
    };
    this.props.updateScriptOptions(details);
    // this.props.updateActiveBrandId(this.state.allGrids[row].bId);
  }

  handleSelect = (event, col, row) => {
    let el = event.target;

    if (el.tagName === "IMG") {
      return;
    }

    if (el.tagName === "SPAN") {
      el = event.target.parentElement;
    }

    if (el.id === "") {
      return;
    }

    // Do nothing if this item is already selected
    if (this.state[col] === el.id) {
      return;
    }

    if (this.state[col] !== "") {
      document
        .getElementById(this.state[col])
        .classList.remove("app__flex-table__cell--selected");
    }
    el.classList.add("app__flex-table__cell--selected");

    this.setState({ [col]: el.id }, () => {
      this.checkGridSelected();
    });
  };

  checkGridSelected = () => {
    let details = {
      intro: this.state.intro,
      contentA: this.state.contentA,
      contentB: this.state.contentB,
      closing: this.state.closing,
    };
    this.props.updateScriptOptions(details);
  };

  createGrid = () => {
    let rows = [];
    let titles = ["intro", "contentA", "contentB", "closing"];

    for (let i = 0; i < this.state.totalRows; i++) {
      let children = [];

      for (let j = 0; j < titles.length; j++) {
        let title = titles[j];
        let items = this.state.gridItems;
        if (items[title][i]) {
          children.push(
            <GridCell
              key={"cell-" + i + "-" + j}
              id={items[title][i]}
              onCellClick={(event) => this.handleSelect(event, title, i)}
              cellText={this.state.gridItems[title][i]}
              isSelected={this.props.scriptDetails[title] === items[title][i]}
            />
          );
        } else {
          children.push(<GridCell key={"cell-" + i + "-" + j} cellText="" />);
        }
      }

      rows.push(
        <div key={"gridrow-" + i} className="app__flex-table__row">
          {children}
        </div>
      );
    }

    return rows;
  };

  render() {
    return (
      <div className="app__container script-grid">
        <div className="script-grid-tabs">
          {this.state.topics.map((row, key) => (
            <button key={'topic-' + key} className={this.state.activeTab === key ? 'grid-tab active' : 'grid-tab'} onClick={() => this.handleTabSelect(row, key)}>{row}</button>
          ))}
        </div>
        <p><b>Select</b> a cell from <b>each</b> column to create your script.</p>
        <div id="emailGridContainer" className="app__script-grid__container">
          {this.state.gridItems["intro"] &&
            this.state.gridItems["contentA"] &&
            this.state.gridItems["contentB"] &&
            this.state.gridItems["closing"] && (
              <div>
                <div className="app__flex-table__row--header">
                  <div className="app__flex-table__cell--header">Intro</div>
                  <div className="app__flex-table__cell--header">Topic</div>
                  <div className="app__flex-table__cell--header">Call to Action</div>
                  <div className="app__flex-table__cell--header">Closing</div>
                </div>

                {this.createGrid()}
              </div>
            )}
        </div>
        <div className='app__standalone-header'>
          <p>Any video message produced by you must adhere to AVEO Oncology corperate communications guidelines.</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.layout.user, bId: state.layout.activeBrand, scriptDetails: state.layout.scriptDetails, selectedTemplateId: state.layout.selectedTemplateId };
};
function mapDispatchToProps(dispatch) {
  return {
    updateScriptOptions: (options) => dispatch(updateScriptOptions(options)),
    updateSelectedTemplateId: (options) => dispatch(updateSelectedTemplateId(options))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ScriptGrid);
