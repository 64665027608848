import React, { Component } from 'react';

class Footer extends Component {
  render() {
    var currentYear = new Date().getFullYear();

    return (
      <footer className='app__footer'>
        <div className='app__footer__container'>
          <div className={'app__footer__logo repcast'}></div>
          <div className={'app__footer__logo aveo'}></div>
          <p className='app__footer__smalltext'>
            &copy; {currentYear} AVEO Pharmaceuticals, Inc. <br />
            All rights reserved. <br />
            CORP-FOT-00641 05/22
            <br />
            <br />
            REPCast is a trademark of
            <br />
            Relevate Health.
            <br />
            REPCast Studio is patent pending. <br />
            &copy; Relevate Health, LLC. All rights reserved.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
